import $ from 'jquery';

const matchHeight = () => {
	function getOffsetTop(elem) {
		let offsetTop = elem.offsetTop;
		if (elem.offsetParent) {
			offsetTop += getOffsetTop(elem.offsetParent);
		}
		return offsetTop;
	}

	function resizeEvent(target) {
		const isRow = target.dataset.matchRow;
		const items = $('.js-match-item', target);
		if (!items.length) {
			return;
		}
		let matchData = {};
		$(target).removeClass('is-matched');
		items.css('height', '').each(function () {
			const offsetTop = isRow === '0' ? 'all' : getOffsetTop(this);

			if (typeof matchData[offsetTop] !== 'object') {
				matchData[offsetTop] = {height: 0, items: $()};
			}
			if (this.offsetHeight > matchData[offsetTop].height) {
				matchData[offsetTop].height = this.offsetHeight;
			}
			matchData[offsetTop].items.push(this);
		});

		for (let i in matchData) {
			const data = matchData[i];
			if (data.height && data.items.length >= 2) {
				data.items.css('height', data.height + 'px');
			}
		}
		$(target).addClass('is-matched');
	}

	let windowWidth = 0;

	$(window).on('load', function () {
		windowWidth = $(window).outerWidth();
		$('.js-match-height').each(function () {
			const wrap = this;
			$(window).on('resize-x', function () {
				resizeEvent(wrap);
			});
		});
		$(window).trigger('resize-x');
	});

	$(window).on('resize orientationchange', function () {
		if (windowWidth !== $(window).outerWidth()) {
			windowWidth = $(window).outerWidth();
			$(window).trigger('resize-x');
		}
	});
};

export default matchHeight;
