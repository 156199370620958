import $ from 'jquery';

const settings = () => {
	let r = document.querySelector(':root');

	$(function () {
		r.style.setProperty('--fh', window.innerHeight + 'px');
	});

	$(window).on('resize orientationchange', function () {
		r.style.setProperty('--fh', window.innerHeight + 'px');
	});
};

export default settings;
