import $ from 'jquery';
import Swiper from 'swiper/bundle';
import Chart from 'chart.js';
import WOW from 'wow.js';
import './components/_lity';
import settings from './components/_settings';
import matchHeight from './components/_matchHeight';

settings();
matchHeight();

$(document).ready(function () {
    $('body').addClass('is-loader');

    const wow = new WOW({
        boxClass: 'wow',
        mobile: true,
    });
    wow.init();
});

$(window).on('load', function () {
    $('.js-lang-curr').on('click', function () {
        $(this).next('.js-lang-list').slideToggle(100);
    });

    // Nav Menu
    //------------------------------------------------------------
    let state = false;
    $('.js-toggle').on('click', function () {
        if (state === false) {
            $(this).addClass('is-active');
            $('.c-header__navwrap').addClass('is-open');
            state = true;
            $('html, body').css('overflow', 'hidden');
        } else {
            $(this).removeClass('is-active');
            $('.c-header__navwrap').removeClass('is-open').addClass('close');
            $('html, body').css('overflow', '');
            setTimeout(function () {
                $('.c-header__navwrap').removeClass('close');
            }, 500);
            state = false;
        }
    });

    $('#primary-menu .menu-item-has-children > a').on('click', function (ev) {
        if ($(window).innerWidth() <= 991) {
            if (!$(this).hasClass('is-child-show')) {
                ev.preventDefault();
                $(this).addClass('is-child-show')
                $(this).next('.sub-menu').slideToggle();
            }
        }
    });

    // slider MV
    //------------------------------------------------------------
    $('.js-swiper').each(function () {
        new Swiper(this, {
            slidesPerView: 1,
            loop: true,
            parallax: true,
            effect: 'slider',
            speed: 1000,
            autoplay: {
                delay: 3000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    });

    // js-swiper-list
    //------------------------------------------------------------
    $('.js-swiper-list').each(function () {
        new Swiper($('.swiper-container', this).get(0), {
            spaceBetween: 20,
            paginationClickable: true,
            resizeObserver: true,
            // slidesPerView: 3,

            navigation: {
                nextEl: $('.swiper-button-next', this).get(0),
                prevEl: $('.swiper-button-prev', this).get(0),
                clickable: true,
            },

            breakpoints: {
                1100: {
                    slidesPerView: 3,
                },
                767: {
                    slidesPerView: 2,
                },
                480: {
                    slidesPerView: 1,
                }
            },
        });
    });

    // js-accordion
    //------------------------------------------------------------
    $('.js-accordion').on('click', function () {
        let $li = $(this).parents('.c-sidebar__pd');
        $li.siblings().removeClass('is-open');
        if ($li.hasClass('is-open')) {
            $li.removeClass('is-open');
        } else {
            $li.addClass('is-open');
        }
    });

    // chart js
    //------------------------------------------------------------
    (function () {
        if (typeof myChartData === 'object') {
            new Chart("myChart", {
                type: "pie",
                data: myChartData,
                options: {
                    cutoutPercentage: 50,
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        display: false,
                    },
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    }
                }
            });

            Chart.plugins.register({
                afterDatasetsDraw: function (chartInstance) {
                    const ctx = chartInstance.chart.ctx;
                    chartInstance.data.datasets.forEach(function (dataset, i) {
                        let meta = chartInstance.getDatasetMeta(i);
                        if (!meta.hidden) {
                            meta.data.forEach(function (element, index) {
                                // Draw the text in black, with the specified font
                                ctx.fillStyle = '#fff';
                                let fontSize;
                                if ($(window).width() < 575) fontSize = 20;
                                else fontSize = 30;
                                let fontStyle = 'normal';
                                let fontFamily = 'SFProDisplay, sans-serif';
                                ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
                                // Just naively convert to string for now
                                let dataString = dataset.data[index].toString();
                                // Make sure alignment settings are correct
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'middle';
                                let position = element.tooltipPosition();
                                ctx.fillText(dataString + '%', position.x, position.y);
                            });
                        }
                    });
                }
            });
        }
    })();

    // counter js
    //------------------------------------------------------------
    const current_lang = window.document.getElementsByTagName('html')[0].lang;
    $.fn.jQuerySimpleCounter = function (options) {
        let settings = $.extend({
            start: 0,
            end: 100,
            easing: 'swing',
            duration: 400,
            complete: ''
        }, options);

        let thisElement = $(this);

        $({count: settings.start}).animate({count: settings.end}, {
            duration: settings.duration,
            easing: settings.easing,
            step: function () {
                let mathCount = Math.ceil(this.count);
                thisElement.text(mathCount.toLocaleString(current_lang));
            },
            complete: settings.complete
        });
    };

    $('#number1').jQuerySimpleCounter({end: parseInt($('#number1').text()), duration: 5000});
    $('#number2').jQuerySimpleCounter({end: parseInt($('#number2').text()), duration: 5000});
    $('#number3').jQuerySimpleCounter({end: parseInt($('#number3').text()), duration: 5000});
    $('#number4').jQuerySimpleCounter({end: parseInt($('#number4').text()), duration: 5000});
    $('#number5').jQuerySimpleCounter({end: parseInt($('#number5').text()), duration: 5000});
});
